import { createSlice } from "@reduxjs/toolkit";

const initialProfileState = {
  actionsLoading: false,
  data: {},
};

const initialThemeState = {
  actionsLoading: false,
  data: {},
};

const initialServicesState = {
  actionsLoading: false,
  data: {},
  serviceTypes: [],
  biodataOptions: [],
  countyOptions: [],
};

const initialUsersState = {
  actionsLoading: false,
  roles: [],
  users: [],
};

const initialCheckinState = {
  actionsLoading: false,
  profiles: [],
};

const initialSettingsState = {
  actionsLoading: false,
  settings: {},
};

const initialCountiesState = {
  actionsLoading: false,
  counties: [],
};

export const systemProfileSlice = createSlice({
  name: "systemProfile",
  initialState: initialProfileState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    systemProfileFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.data = data;
    },
  }
});

export const systemThemeSlice = createSlice({
  name: "systemTheme",
  initialState: initialThemeState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    systemThemeFetched: (state, action) => {
      const { system } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.data = system;
    },
  }
});

export const systemServicesSlice = createSlice({
  name: "systemServices",
  initialState: initialServicesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    systemServicesFetched: (state, action) => {
      const { data, service_types, biodata_options, county_options } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.data = data;
      state.serviceTypes = service_types;
      state.biodataOptions = biodata_options;
      state.countyOptions = county_options;
    },
  }
});

export const systemUsersSlice = createSlice({
  name: "systemUsers",
  initialState: initialUsersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    systemUsersFetched: (state, action) => {
      const { users, roles } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.users = users;
      state.roles = roles;
    },
  }
});

export const checkinSlice = createSlice({
  name: "checkin",
  initialState: initialCheckinState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    profilesFetched: (state, action) => {
      const { profiles } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.profiles = profiles;
    },
  }
});

export const systemSettingsSlice = createSlice({
  name: "systemSettings",
  initialState: initialSettingsState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    systemSettingsFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.settings = data;
    },
  }
});

export const countiesSlice = createSlice({
  name: "counties",
  initialState: initialCountiesState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      state.actionsLoading = false;
    },
    startCall: (state, action) => {
      state.error = null;
      state.actionsLoading = true;
    },
    countiesFetched: (state, action) => {
      const { data } = action.payload;
      state.actionsLoading = false;
      state.error = null;
      state.counties = data;
    },
  }
});
