/* eslint-disable jsx-a11y/role-supports-aria-props */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive, hasPermission, appConfigs } from "../../../../_helpers";
// import { Badge } from "react-bootstrap";
import { shallowEqual, useSelector } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    // console.log("URL: " + url + ", isActive: " + checkIsActive(location, url));
    return checkIsActive(location, url) ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open menu-item-not-hightlighted` : "";
  };

  const { user, settings } = useSelector(
    (state) => ({
      user: state.auth.user,
      settings: state.systemSettings.settings,
    }),
    shallowEqual
  );

  const [role, setRole] = useState(undefined);
  const [permissions, setPermissions] = useState([]);

  useEffect(() => {
    if (user?.profiles?.active.type === 'agent') {
      setPermissions(settings?.agentPermissions ? JSON.parse(settings?.agentPermissions) : []);
    } else {
      setPermissions(user?.profiles?.active?.role?.permissions || []);
    }
    setRole((user?.profiles?.active.is_owner === 1 ? 'admin' : (user?.profiles?.active.type === 'agent' ? 'agent' : 'user')));
    // console.log(user?.profiles?.active.role);
  }, [user, settings]);

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <li className={`menu-item mb-5 ${getMenuItemActive("/onboard", false)}`} aria-haspopup="true" >
          <NavLink className="menu-link onboard" to="/onboard">
            <span className="svg-icon menu-icon svg-icon-primary"><SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Plus.svg")} /></span>
            <span className="menu-text font-weight-bolder text-primary">Onboard Group</span>
          </NavLink>
        </li>
        <li className={`menu-item mt-1 ${getMenuItemActive("/dashboard", false)}`} aria-haspopup="true" >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl("/media/svg/icons/Layout/Layout-arrange.svg")} /></span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>
        {hasPermission(permissions, role, 'accessToGroupsData') && <li className={`menu-item mt-1 ${getMenuItemActive("/groups", false)}`} aria-haspopup="true" >
          <NavLink className="menu-link" to="/groups">
            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Group.svg")} /></span>
            <span className="menu-text">Groups</span>
          </NavLink>
        </li>}
        {appConfigs.slug === 'bank' && hasPermission(permissions, role, 'accessToBranchesData') && <li className={`menu-item mt-1 ${getMenuItemActive("/branches", false)}`} aria-haspopup="true" >
          <NavLink className="menu-link" to="/branches">
            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl("/media/svg/icons/Design/Difference.svg")} /></span>
            <span className="menu-text">Branches</span>
          </NavLink>
        </li>}
        {hasPermission(permissions, role, 'accessToAgentsData') && <li className={`menu-item mt-1 ${getMenuItemActive("/agents", false)}`} aria-haspopup="true" >
          <NavLink className="menu-link" to="/agents">
            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl("/media/svg/icons/General/User.svg")} /></span>
            <span className="menu-text">Agents</span>
          </NavLink>
        </li>}
        {/* {appConfigs.slug === 'bank' && <li className={`menu-item mt-1 ${getMenuItemActive("/reports", false)} opacity-30`} aria-haspopup="true" >
          <NavLink className="menu-link" to="/reports">
            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")} /></span>
            <span className="menu-text">Reports</span>
            <small style={{ paddingTop: '6px' }} className="d-none d-md-inline-block">
              <Badge pill variant="secondary" className="px-3">Coming Soon</Badge>
            </small>
          </NavLink>
        </li>} */}
        {(hasPermission(permissions, role, 'accessToGroupsReports') ||
          hasPermission(permissions, role, 'accessToAgentsReports') ||
          hasPermission(permissions, role, 'accessToMeetingsReports') ||
          hasPermission(permissions, role, 'accessToSavingsReports') ||
          hasPermission(permissions, role, 'accessToLoansReports')) && appConfigs.slug === 'ngo' && <li className={`menu-item mt-1 ${getMenuItemActive("/reports", false)}`} aria-haspopup="true" data-menu-toggle="hover" >
            <NavLink className="menu-link menu-toggle" to="/reports">
              <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl("/media/svg/icons/Files/Selected-file.svg")} /></span>
              <span className="menu-text">Reports</span>
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Reports</span>
                  </span>
                </li>
                {hasPermission(permissions, role, 'accessToGroupsReports') && <li className={`menu-item ${getMenuItemActive("/reports/-groups")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/reports/-groups">
                    <i className="menu-bullet menu-bullet-line"><span /></i>
                    <span className="menu-text">Groups</span>
                  </NavLink>
                </li>}
                {/* {hasPermission(permissions, role, 'accessToGroupsSettingsEdit') && <li className={`menu-item ${getMenuItemActive("/reports/-branches")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/reports/-branches">
                  <i className="menu-bullet menu-bullet-line"><span /></i>
                  <span className="menu-text">Branches</span>
                </NavLink>
              </li>} */}
                {hasPermission(permissions, role, 'accessToAgentsReports') && <li className={`menu-item ${getMenuItemActive("/reports/-agents")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/reports/-agents">
                    <i className="menu-bullet menu-bullet-line"><span /></i>
                    <span className="menu-text">Agents</span>
                  </NavLink>
                </li>}
                {hasPermission(permissions, role, 'accessToMeetingsReports') && <li className={`menu-item ${getMenuItemActive("/reports/-meetings")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/reports/-meetings">
                    <i className="menu-bullet menu-bullet-line"><span /></i>
                    <span className="menu-text">Meetings</span>
                  </NavLink>
                </li>}
                {/* {hasPermission(permissions, role, 'accessToGroupsSettingsEdit') && <li className={`menu-item ${getMenuItemActive("/reports/-kpi")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/reports/-kpi">
                  <i className="menu-bullet menu-bullet-line"><span /></i>
                  <span className="menu-text">KPI</span>
                </NavLink>
              </li>} */}
                {hasPermission(permissions, role, 'accessToSavingsReports') && <li className={`menu-item ${getMenuItemActive("/reports/-savings")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/reports/-savings">
                    <i className="menu-bullet menu-bullet-line"><span /></i>
                    <span className="menu-text">Savings</span>
                  </NavLink>
                </li>}
                {hasPermission(permissions, role, 'accessToLoansReports') && <li className={`menu-item ${getMenuItemActive("/reports/-loans")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/reports/-loans">
                    <i className="menu-bullet menu-bullet-line"><span /></i>
                    <span className="menu-text">Loans</span>
                  </NavLink>
                </li>}
                {/* {hasPermission(permissions, role, 'accessToGroupsSettingsEdit') && <li className={`menu-item ${getMenuItemActive("/reports/-location")}`} aria-haspopup="true">
                <NavLink className="menu-link" to="/reports/-location">
                  <i className="menu-bullet menu-bullet-line"><span /></i>
                  <span className="menu-text">Location</span>
                </NavLink>
              </li>} */}
              </ul>
            </div>
          </li>}
        {(hasPermission(permissions, role, 'accessToInstitutionSettings') ||
          hasPermission(permissions, role, 'accessToInstitutionProfileEdit') ||
          hasPermission(permissions, role, 'accessToInstitutionBrandingEdit') ||
          hasPermission(permissions, role, 'accessToUsersRead') ||
          hasPermission(permissions, role, 'accessToGroupsSettingsEdit')) && <li className={`menu-item menu-item-submenu mt-1 ${getMenuItemActive("/settings", true)}`} aria-haspopup="true" data-menu-toggle="hover">
            <NavLink className="menu-link menu-toggle" to="/settings">
              <span className="svg-icon menu-icon">
                <SVG src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")} />
              </span>
              <span className="menu-text">Settings</span>
            </NavLink>
            <div className="menu-submenu">
              <i className="menu-arrow" />
              <ul className="menu-subnav">
                <li className="menu-item menu-item-parent" aria-haspopup="true">
                  <span className="menu-link">
                    <span className="menu-text">Settings</span>
                  </span>
                </li>
                {hasPermission(permissions, role, 'accessToInstitutionProfileEdit') && <li className={`menu-item ${getMenuItemActive("/settings/profile")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/settings/profile">
                    <i className="menu-bullet menu-bullet-line"><span /></i>
                    <span className="menu-text">Profile</span>
                  </NavLink>
                </li>}
                {hasPermission(permissions, role, 'accessToInstitutionBrandingEdit') && <li className={`menu-item ${getMenuItemActive("/settings/theming")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/settings/theming">
                    <i className="menu-bullet menu-bullet-line"><span /></i>
                    <span className="menu-text">Logo &amp; Theme</span>
                  </NavLink>
                </li>}
                {hasPermission(permissions, role, 'accessToGroupsSettingsEdit') && <li className={`menu-item ${getMenuItemActive("/settings/services")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/settings/services">
                    <i className="menu-bullet menu-bullet-line"><span /></i>
                    <span className="menu-text">Groups</span>
                  </NavLink>
                </li>}
                {hasPermission(permissions, role, 'accessToUsersRead') && <li className={`menu-item ${getMenuItemActive("/settings/users-roles")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/settings/users-roles">
                    <i className="menu-bullet menu-bullet-line"><span /></i>
                    <span className="menu-text">Users &amp; Roles</span>
                  </NavLink>
                </li>}
                {hasPermission(permissions, role, 'accessToInstitutionSettings') && <li className={`menu-item ${getMenuItemActive("/settings/system")}`} aria-haspopup="true">
                  <NavLink className="menu-link" to="/settings/system">
                    <i className="menu-bullet menu-bullet-line"><span /></i>
                    <span className="menu-text">System</span>
                  </NavLink>
                </li>}
              </ul>
            </div>
          </li>}
        <li className={`menu-item ${getMenuItemActive("/share", false)}`} aria-haspopup="true" >
          <NavLink className="menu-link" to="/share">
            <span className="svg-icon menu-icon"><SVG src={toAbsoluteUrl("/media/svg/icons/Communication/Share.svg")} /></span>
            <span className="menu-text">Share</span>
          </NavLink>
        </li>
      </ul>
      {/* end::Menu Nav */}
    </>
  );
}
