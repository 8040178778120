import { createSlice } from '@reduxjs/toolkit';

const initialGroupTypesState = {
    actionsLoading: false,
    types: [],
};

const initialCountriesState = {
    actionsLoading: false,
    countries: [],
};

const initialGroupInfoUpdateState = {
    actionsLoading: false,
    group: [],
};

const initialMembersInfoState = {
    actionsLoading: false,
    members: [],
};

const initialGroupContributionsState = {
    actionsLoading: false,
    contributions: [],
    defaults: {},
};

const initialGroupLoansState = {
    actionsLoading: false,
    loans: [],
    defaults: {},
};

const initialGroupAccountsState = {
    actionsLoading: false,
    accounts: [],
    defaults: {},
};

const initialGroupConfirmState = {
    actionsLoading: false,
    defaults: {},
};

const initialBanksState = {
    actionsLoading: false,
    banks: [],
};

const initialSaccosState = {
    actionsLoading: false,
    saccos: [],
};

const initialBankBranchesState = {
    actionsLoading: false,
    branches: [],
};

const initialSaccoBranchesState = {
    actionsLoading: false,
    branches: [],
};

const initialMobileMoneyProvidersState = {
    actionsLoading: false,
    mobileMoneyProviders: [],
};

// grouptypes
export const groupTypesSlice = createSlice({
    name: 'groupTypes',
    initialState: initialGroupTypesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        groupTypesFetched: (state, action) => {
            const { service_types } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.types = service_types;
        },
    },
});

// countries

export const countriesSlice = createSlice({
    name: 'countries',
    initialState: initialCountriesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        countriesFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.countries = data;
        },
    },
});

// group-info

export const groupInfoSlice = createSlice({
    name: 'groupInfo',
    initialState: initialGroupInfoUpdateState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        groupInfoUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.group = data;
        },
    },
});

// members-info

export const membersInfoSlice = createSlice({
    name: 'membersInfo',
    initialState: initialMembersInfoState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        membersFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.members = data;
        },
        membersUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.members = data;
        },
    },
});

// group-contributions

export const groupContributionsSlice = createSlice({
    name: 'groupContributions',
    initialState: initialGroupContributionsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        groupContributionsDefaultsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.defaults = data;
        },
        groupContributionsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.contributions = data;
        },
        groupContributionsUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.contributions = data;
        },
    },
});

// group-loans

export const groupLoansSlice = createSlice({
    name: 'groupLoans',
    initialState: initialGroupLoansState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        groupLoansDefaultsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.defaults = data;
        },
        groupLoansFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.loans = data;
        },
        groupLoansUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.loans = data;
        },
    },
});

// group-accounts

export const groupAccountsSlice = createSlice({
    name: 'groupAccounts',
    initialState: initialGroupAccountsState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        groupAccountsDefaultsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.defaults = data;
        },
        groupAccountsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.accounts = data;
        },
        groupAccountsUpdated: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.accounts = data;
        },
    },
});

// group-confirmation

export const groupConfirmSlice = createSlice({
    name: 'groupConfirm',
    initialState: initialGroupConfirmState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        groupSummaryDefaultsFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.defaults = data;
        },
    },
});

// banks

export const banksSlice = createSlice({
    name: 'banks',
    initialState: initialBanksState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        banksFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.banks = data;
        },
    },
});

// bank branches

export const bankBranchesSlice = createSlice({
    name: 'bankBranches',
    initialState: initialBankBranchesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        bankBranchesFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.branches = data;
        },
    },
});

// sacco
export const saccosSlice = createSlice({
    name: 'saccos',
    initialState: initialSaccosState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        saccosFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.saccos = data;
        },
    },
});

// sacco branches

export const saccoBranchesSlice = createSlice({
    name: 'saccoBranches',
    initialState: initialSaccoBranchesState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        saccoBranchesFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.branches = data;
        },
    },
});

// mobile money providers

export const mobileMoneyProvidersSlice = createSlice({
    name: 'mobileMoneyProviders',
    initialState: initialMobileMoneyProvidersState,
    reducers: {
        catchError: (state, action) => {
            state.error = `${action.type}: ${action.payload.error}`;
            state.actionsLoading = false;
        },
        startCall: (state, action) => {
            state.error = null;
            state.actionsLoading = true;
        },
        mobileMoneyProvidersFetched: (state, action) => {
            const { data } = action.payload;
            state.actionsLoading = false;
            state.error = null;
            state.mobileMoneyProviders = data;
        },
    },
});
